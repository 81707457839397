<template>
  <div>
    <article>
      <section>
        <p>
          欢迎参加快音APP的“领时长”活动！为了确保您能够顺利且愉快地参与，请仔细阅读并理解以下规则。参加本活动即表示您同意遵守所有规定。
        </p>
      </section>
      <section>
        <h3>一、活动时间</h3>
        <p>
          活动的具体结束时间将根据平台运营情况调整，具体请以实际通知为准。我们会在活动页面及时更新相关信息，以便您了解最新的活动期限。
        </p>
      </section>
      <section>
        <h3>二、参与资格</h3>
        <p>
          本活动仅限于收到快音APP（以下简称“平台”）邀请的用户参与。每位受邀用户的任务类型由平台随机分配，并非所有用户都能参与所有任务。具体您是否为受邀用户及能够参与的任务类型，请以活动页面展示的信息为准。平台会根据运营策略或优化用户体验的需求，对任务类型或规则进行调整或增减，具体内容以活动页面提示为准。
        </p>
      </section>
      <section>
        <h3>三、活动内容</h3>
        <h4>1. 获得奖励时长</h4>
        <p>
          通过完成指定任务，您可以获得额外的“奖励时长”，用于免费收听平台提供的音乐内容。奖励时长不会影响您当前使用的设备功能、登录状态及消耗。具体规则和任务要求请参考活动页面的详细说明。
        </p>
        <h4>2. 任务类型</h4>
        <h5>2.1 看视频领时长</h5>
        <p>
          您可以通过观看平台内指定的视频来获得奖励时长。每个视频之间设有随机的冷却期，即在您看完一个视频后，需等待一段时间才能继续领取下一时长。冷却期的具体时长将在活动页面上明确标注。此外，您有机会通过观看一定数量的指定视频解锁全天畅听模式，甚至提前解锁多天的全天畅听权限。提醒：如果您的剩余时长不足以覆盖到当天24点，即使获得了额外的奖励时长或者全天畅听模式也将在当日24点自然结束，为了最大化利用全天畅听模式，请尽量在较早的时间段完成解锁任务，以确保您可以充分享受全天的音乐服务。
        </p>
        <h5>2.2 浏览商品领时长</h5>
        <p>您还可以通过在活动页面浏览商品来获得相应的奖励时长。具体的浏览时间和奖励时长将在活动页面上详细说明。</p>
        <h5>2.3 其他时长任务</h5>
        <p>
          平台会不定期推出更多类型的时长任务，例如连续签到领时长等。具体信息将在任务页面中详细说明。用户是否能参与这些任务，以及可获得的奖励数量均为平台随机分配。其他任务的详细信息以任务页面说明为准，未说明的事项则适用本活动规则。
        </p>
      </section>
      <section>
        <h3>四、注意事项</h3>
        <h4>1.禁止舞弊行为</h4>
        <p>
          我们严格禁止任何利用非官方手段（如模拟器、插件、外挂等）参与活动的行为。一旦发现舞弊行为，平台有权采取包括但不限于限制登录、封禁账号等措施，并保留追究法律责任的权利。舞弊行为包括但不限于下载非官方客户端、使用非法工具扫码、注册多个账号、篡改设备数据、买卖账号或时长牟利、作弊非法获利等。
        </p>
        <h4>2.免责条款</h4>
        <p>
          对于因不可抗力（如自然灾害、政策变动、网络攻击等）导致的活动调整或无法正常进行，平台不承担相关责任。同时，由于用户个人操作不当或第三方原因造成的参与失败，平台也不承担责任。请务必通过快音官方客户端参与活动，谨防诈骗。若有人或第三方以平台名义宣称或从事类似活动，请提高警惕并向平台报告。
        </p>
        <h4>3.权利不可转让</h4>
        <p>未经平台书面许可，您不得转让因参与活动所获得的任何权利或福利。</p>
        <h4>4. 规则更新</h4>
        <p>
          平台有权基于运营需求对活动规则进行修改或调整。变更内容将在活动页面上公布，并自公布之日起生效。如果您不同意新的规则，可以选择不再参与活动。我们建议您定期查看活动页面，以获取最新的规则信息。
        </p>
        <h4>5. 合法合规</h4>
        <p>
          严禁利用本活动进行博彩或其他违法行为。一经发现，平台将取消该用户的活动资格并收回已获得的福利，情节严重者将被移交司法机关处理。如果您的行为给平台造成损失，您还需承担赔偿责任。
        </p>
        <h4>6. 适用协议</h4>
        <p>
          《快音用户服务协议》和《隐私政策》同样适用于本次活动。如有冲突之处，以本次活动规则为准。未尽事宜仍遵循《快音用户服务协议》和《隐私政策》的规定。
        </p>
      </section>
      <section>
        <h3>五、反馈与帮助</h3>
        <p>
          如果您在活动中遇到问题或有任何建议，可以通过【我的】-【意见反馈】向我们提交您的问题或意见。我们将尽力为您提供帮助和支持，确保您拥有良好的活动体验。
        </p>
      </section>
      <section>
        <p>希望您能在遵守规则的前提下，尽情享受活动带来的乐趣和优惠！感谢您对快音的支持。</p>
      </section>
    </article>
  </div>
</template>
<script>
  export default {
    mounted() {
      document.title = '快音APP领时长活动规则';
    },
  };
</script>
<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 16px;
  }
  h4 {
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    text-indent: 2em;
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
