export default [
  // 已收集个人信息清单
  {
    path: '/personal-info-collect/list',
    name: 'PersonalInfoCollectList',
    component: () => import('@/views/App/PersonalInfoCollect/PersonalInfoCollectList'),
  },
  // 已收集个人信息种类
  {
    path: '/personal-info-collect/kind',
    name: 'PersonalInfoCollectKind',
    component: () => import('@/views/App/PersonalInfoCollect/PersonalInfoCollectKind'),
  },
  // 已收集个人信息内容详情
  {
    path: '/personal-info-collect/content',
    name: 'PersonalInfoCollectContent',
    component: () => import('@/views/App/PersonalInfoCollect/PersonalInfoCollectContent'),
  },
  // 已收集个人信息清单 备案
  {
    path: '/personal-info-collect-ba/list',
    name: 'PersonalInfoCollectListBa',
    component: () => import('@/views/App/PersonalInfoCollect-ba/PersonalInfoCollectList'),
  },
  // 已收集个人信息种类 备案
  {
    path: '/personal-info-collect-ba/kind',
    name: 'PersonalInfoCollectKindBa',
    component: () => import('@/views/App/PersonalInfoCollect-ba/PersonalInfoCollectKind'),
  },
  // 已收集个人信息内容详情 备案
  {
    path: '/personal-info-collect-ba/content',
    name: 'PersonalInfoCollectContentBa',
    component: () => import('@/views/App/PersonalInfoCollect-ba/PersonalInfoCollectContent'),
  },
  // widgets分享页
  {
    path: '/widgets/share',
    name: 'WidgetsShare',
    component: () => import('@/views/App/Widgets/Share'),
  },
  // widgets
  {
    path: '/widgets/app-mobile-config',
    name: 'AppMobileConfig',
    component: () => import('@/views/App/Widgets/AppMobileConfig'),
  },
  // widgets教程页
  {
    path: '/widgets/tutorial/:type?',
    name: 'WidgetsTutorial',
    component: () => import('@/views/App/Widgets/Tutorial.vue'),
    props: (route) => ({ type: route.params.type }),
  },
  // widgets新分享页
  {
    path: '/widgets/landing-page',
    name: 'WidgetsLanding',
    component: () => import('@/views/App/Widgets/LandingPage'),
  },
  {
    path: '/iuv-activity-share',
    name: 'IuvActivityShare',
    component: () => import('@/views/App/Iuv/IuvActivityShare.vue'),
  },
  // 快音 widgets教程页
  {
    path: '/ky-widgets/tutorial/:type?',
    name: 'KyWidgetsTutorial',
    component: () => import('@/views/App/KYWidgets/Tutorial.vue'),
    props: (route) => ({ type: route.params.type }),
  },
  {
    path: '/l',
    name: 'NoteLandingPage',
    component: () => import('@/views/App/Ky/NoteLandingPage.vue'),
  },
  {
    path: '/music-lib/rule',
    name: 'MusicLibRule',
    component: () => import('@/views/App/MusicLib/WatchAdGetRewardsRule.vue'),
  },
  {
    path: '/music-lib/privacy-protocol',
    name: 'MusicLibPrivacyProtocol',
    component: () => import('@/views/App/MusicLib/PrivacyProtocol.vue'),
  },
  {
    path: '/music-lib/user-agreement',
    name: 'MusicLibUserAgreement',
    component: () => import('@/views/App/MusicLib/UserAgreement.vue'),
  },
  {
    path: '/music-lib/terms-of-service',
    name: 'MusicLibTermsOfService',
    component: () => import('@/views/App/MusicLib/TermsOfService.vue'),
  },
  // 小程序已收集个人信息清单
  {
    path: '/music-lib/personal-info-collect/list',
    name: 'MusicLibPersonalInfoCollectList',
    component: () => import('@/views/App/MusicLib/PersonalInfoCollect/PersonalInfoCollectList'),
  },
  // 小程序已收集个人信息种类
  {
    path: '/music-lib/personal-info-collect/kind',
    name: 'MusicLibPersonalInfoCollectKind',
    component: () => import('@/views/App/MusicLib/PersonalInfoCollect/PersonalInfoCollectKind'),
  },
  // 小程序已收集个人信息内容详情
  {
    path: '/music-lib/personal-info-collect/content',
    name: 'MusicLibPersonalInfoCollectContent',
    component: () => import('@/views/App/MusicLib/PersonalInfoCollect/PersonalInfoCollectContent'),
  },
  // 快音AI写歌第三方信息共享清单
  {
    path: '/music-lib/sdk-list',
    name: 'MusicLibSDKList',
    component: () => import('@/views/App/MusicLib/SDKList'),
  },
  {
    path: '/icp-filing',
    name: 'ICPFiling',
    component: () => import('@/views/App/Ky/ICPFiling.vue'),
  },
  {
    path: '/generation-service-terms',
    name: 'GenerationServiceTerms',
    component: () => import('@/views/App/Ky/GenerationServiceTerms.vue'),
  },
];
