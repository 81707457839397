<template>
  <div>
    <ShareLandingNew :umLink="umLink" :copyDownload="copyDownload" v-if="inviteLandingAb !== 'd'"></ShareLandingNew>
    <!-- <ShareLandingOld :copyDownload="copyDownload" v-else></ShareLandingOld> -->
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import ShareLandingNew from '@/views/Welfare/Fission/ShareLanding/ShareLandingNew';
  import ShareLandingOld from '@/views/Welfare/Fission/ShareLanding/ShareLandingOld';
  import API from '@/api';
  import Download from '@/utils/download';
  import Clipboard from 'clipboard';
  import { mapGetters } from 'vuex';
  import InitUmLink from '@/utils/init-ulink';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';

  export default {
    name: 'index',
    async asyncData({ store, $http, params }) {
      const {
        user_info,
        invite_code,
        clipboard_txt,
        music_list,
        invite_landing_ab,
        show_max_reward,
        evaluation,
        shuffling_info,
        invite_landing_ab_v2,
        marquee_friend_withdrawl,
        quick_app,
      } = await $http.post(API.GET_INVITE_LANDING_PAGE_INFO, {
        ...params,
      });
      store.commit('share/shareLanding/SET_USER_INFO', user_info);
      store.commit('share/shareLanding/SET_INVITE_CODE', invite_code);
      store.commit('share/shareLanding/SET_CLIPBOARD_TEXT', clipboard_txt);
      store.commit('share/shareLanding/SET_MUSIC_LIST', music_list);
      store.commit('share/shareLanding/SET_SHUFFLING_INFO', shuffling_info);
      store.commit('share/shareLanding/SET_MAX_REWARD', show_max_reward);
      store.commit('share/shareLanding/SET_EVALUATION', evaluation);
      store.commit('share/shareLanding/SET_MARQUEE_FRIEND', marquee_friend_withdrawl);
      store.commit('share/shareLanding/SET_LANDING_AB', invite_landing_ab);
      store.commit('share/shareLanding/SET_LANDING_AB_V2', invite_landing_ab_v2);
      store.commit('share/shareLanding/SET_QUICK_INFO', quick_app || {});
      // 财神跳一跳随机数据
      let arr = [1, 2, 3];
      let index = Math.floor(Math.random() * arr.length);
      store.commit('share/shareLanding/SET_SELECT_CHILD_INDEX', arr[index]);
      let page_title;
      switch (invite_landing_ab_v2) {
        case 'd':
          page_title = '飞行降落A';
          break;
        case 'e':
          page_title = '飞行降落B';
          break;
        case 'f':
          page_title = '落地页搞笑';
          break;
        case 'g':
          page_title = '飞行降落C';
          break;
        case 'h':
          page_title = '财神跳一跳';
          break;
      }
      store.commit('share/shareLanding/SET_PAGE_TITLE', page_title);
    },
    components: { ShareLandingNew, ShareLandingOld, IntroModal },
    computed: {
      ...mapGetters('share/shareLanding', [
        'userInfo',
        'inviteCode',
        'clipboardText',
        'musicList',
        'inviteLandingAb',
        'QuickInfo',
      ]),
      ...mapGetters(['traceId']),
      appName() {
        return this.$store.getters.client['app-name'];
      },
    },
    beforeMount() {
      this.fissionTrace();
      try {
        // 判断跳转快应用
        let { is_quick_app, business, quick_app_url, package: packageName } = this.QuickInfo;
        if (window.channelReady && is_quick_app && quick_app_url) {
          window.channelReady((bAvailable) => {
            if (window.appRouter && bAvailable) {
              let query = { businessIndex: business === 'music' ? 0 : 1, ...this.$route.query };
              window.appRouter(packageName, quick_app_url, query);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    mounted() {
      this.$http.TaskPost(API.INVITE_CODE, { invite_code: this.inviteCode });
      this.initULink();
    },
    data() {
      return {
        sharePosition: this.$route.query.share_position || 'fission',
        umLink: {},
        showIntro: false,
      };
    },
    methods: {
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
      /**
       * @description: 裂变溯源
       */

      fissionTrace() {
        this.$store.commit('setTraceId');
        this.$track({
          page_title: `裂变落地页`,
          element_name: '进入页面',
          remarks: this.traceId,
        });
      },

      /**
       * @description: 下载
       */

      copyDownload(element, element_name, pageTitle, remarks) {
        try {
          this.$track({
            page_title: pageTitle || `任务落地页${this.inviteLandingAb}`,
            element_name,
            remarks: remarks || `${this.userInfo.uid};${this.inviteCode};${this.sharePosition}`,
          });

          this.$track({
            page_title: `裂变落地页`,
            element_name: '下载',
            remarks: this.traceId,
          });

          const jump = () => {
            let url = '';

            if (this.$platform.Android) {
              url = Download.downLoad('yyb');
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              // url = Download.downLoad('fission', { mode: 'divide' });
              url = Download.downLoad('yyb');
            }

            window.location.href = url;
          };
          const clipboard = new Clipboard(element);

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });
          // // 使用友盟U-link
          // if (this.umLink.isUmLinkInitSuccess) {
          //   return;
          // }
          clipboard.on('success', (e) => {
            console.log(`success`);
            e.clearSelection();
            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
          // jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },

      /**
       * @description: 初始化友盟 ULink SDK
       **/

      initULink() {
        let selectorStr =
          '#dl-btn, #rp-btn, #new-download-btn, #modal-close-btn, #auto-download-btn,#v1-get-btn,#v2-get-btn,#v1-download-btn,#v2-download-btn,#v3-close-btn,#v3-get-btn,#v3-download-btn,#v3-auto-download,#v4-giveMe-btn-one,#v4-download-btn,#v4-auto-download,#v4-giveMe-btn-two,#v4-close-btn,#immediate-btn,#v5-auto-download,#LuckyRedBagBtn,#retain-get-btn,#bestLuck-modal-close';
        if (this.musicList.length > 0) {
          this.musicList.forEach((item, index) => {
            selectorStr = `${selectorStr},#ml-btn${index}`;
          });
        }
        let linkId = 'usr13t43gs2mbp32';

        switch (this.sharePosition) {
          case 'shiwu':
            linkId = 'usr1bpco8ugaohsg';
            break;
          case 'wuren':
            linkId = 'usr1ju0iuu90n44h';
            break;
          case 'liucun':
            linkId = 'usr1dhjevf21lrs8';
            break;
          default:
            linkId = 'usr11tromu78dihk';
        }
        this.umLink = new InitUmLink({
          selector: selectorStr,
          linkId,
          pageTitle: `任务落地页${this.inviteLandingAb}`,
          clipboardText: this.clipboardText,
          traceId: this.traceId,
        });
      },
    },
  };
</script>

<style scoped></style>
